<script>
    import { onMount, onDestroy } from 'svelte';
    import axios from 'axios';
    export let transaction;
    let isLoading = true;
    let error = null;
    let status = null;
    let interval;
    let attempts = 0;
    let redirectCountdown = 0;
    const MAX_ATTEMPTS = 10; // 10 seconds with 1-second interval
    const INITIATED_STATUS = 'initiated';
    const PENDING_STATUS = 'pending';
    const CANCELED_STATUS = 'canceled';
    const APPROVED_STATUS = 'approved';
    const DECLINED_STATUS = 'declined';
    const REFUNDED_STATUS = 'refunded';
    const PARTIALLY_PAID_STATUS = 'partially-paid';
    const NOT_FOUND_STATUS = 'notfound';
    function clearUrlParameters() {
      const url = new URL(window.location.href);
      window.history.replaceState({}, document.title, url.pathname);
    }
    async function checkPaymentStatus() {
      try {
        const requestData = {
          "transaction_id": transaction.id
        };
        const response = await axios.post('/payment/api/ccbillstatus', requestData);
        status = response.data[0].status;
        attempts++;
        if (status !== INITIATED_STATUS) {
          clearInterval(interval);
          isLoading = false;
          if (status === NOT_FOUND_STATUS) {
            error = 'Item Not Found';
            redirectCountdown = 3;
            // Start countdown timer
            const countdownInterval = setInterval(() => {
              redirectCountdown--;
              if (redirectCountdown <= 0) {
                clearInterval(countdownInterval);
                window.location.href = '/wallet';
              }
            }, 1000);
            return false;
          } else if (status === APPROVED_STATUS) {
            window.location.href = '/wallet';
          } else {
            error = `Payment ${status}. Please contact support if you think this is an error.`;
          }
        } else if (attempts >= MAX_ATTEMPTS) {
          clearInterval(interval);
          isLoading = false;
          error = "We are still verifying your payment. Please check your wallet in a few minutes.";
          setTimeout(() => {
            window.location.href = '/wallet';
          }, 5000);
        }
      } catch (err) {
        console.error('Error checking payment status:', err);
        error = 'Failed to verify payment status. Please check your wallet for confirmation.';
        clearInterval(interval);
        isLoading = false;
      }
    }
    onMount(() => {
      clearUrlParameters();
      checkPaymentStatus();
      interval = setInterval(checkPaymentStatus, 1000);
    });
    onDestroy(() => {
      if (interval) clearInterval(interval);
    });
    function retryCheck() {
      error = null;
      isLoading = true;
      attempts = 0;
      checkPaymentStatus();
      interval = setInterval(checkPaymentStatus, 1000);
    }
  </script>

  {#if error}
    <div class="flex items-center justify-center h-screen bg-gray-100">
      <div class="text-center">
        <div class="text-red-500 text-xl mb-4">
          <svg class="w-12 h-12 mx-auto mb-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
          Status Update
        </div>
        <p class="text-gray-700">{error}</p>
        {#if status === NOT_FOUND_STATUS && redirectCountdown > 0}
          <p class="mt-2 text-sm text-gray-500">
            Redirecting in {redirectCountdown} seconds...
          </p>
        {/if}
        {#if status !== APPROVED_STATUS && status !== INITIATED_STATUS && status !== NOT_FOUND_STATUS}
          <button
            on:click={retryCheck}
            class="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            Retry Verification
          </button>
        {/if}
      </div>
    </div>
  {:else}
    <div class="flex items-center justify-center h-screen bg-gray-100">
      <div class="text-center">
        <div class="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid mx-auto"></div>
        <p class="mt-4 text-lg font-medium text-gray-700">
          Verifying your payment...
        </p>
        <p class="mt-2 text-sm text-gray-500">
          Please do not close this window
        </p>
        <p class="mt-2 text-xs text-gray-400">
          Time remaining: {MAX_ATTEMPTS - attempts} seconds
        </p>
      </div>
    </div>
  {/if}